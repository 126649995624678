import {createApp} from "vue";
import App from "./App.vue";
import {createRouter, createWebHashHistory} from 'vue-router'
import vuetify from "./plugins/vuetify";
import PrimeVue from 'primevue/config';
import ToastService from 'primevue/toastservice';


import 'primevue/resources/themes/saga-blue/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';
import "primeflex/primeflex.css";
import './assets/reset.scss';


import {loadFonts} from './plugins/webfontloader'
import Inputs from "./views/Inputs/InputsScreen.vue";
import Meetings from "./views/Meetings/MeetingOverview.vue";
import Registrations from "./views/Registrations/RegistrationsScreen.vue";
import Comment from "./views/CommentEdit/CommentEdit.vue";
import Signin from "./views/SigninScreen.vue";
import Database from "./views/Database/DatabaseMain.vue";
import Admin from "./views/Admin/AdminMain.vue";
import moment from "moment";
import {defaultConfig, plugin} from '@formkit/vue'
import '@formkit/themes/genesis'
import ConfirmationService from 'primevue/confirmationservice';
import "@aws-amplify/ui-components";
import {applyPolyfills, defineCustomElements,} from "@aws-amplify/ui-components/loader";
import Amplify from "aws-amplify";
import AmplifyVue from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';

import {Hub} from "@aws-amplify/core"
import Auth from "@aws-amplify/auth"
import {API} from "aws-amplify";

import store from './store/index.js'

import awsconfig from "./aws-exports.js";


let user;

getUser().then((user) => {
    if (user) {
        router.push({path: '/'});
    }
});

function getUser() {
    return Auth.currentAuthenticatedUser().then((data) => {
        if (data && data.signInUserSession) {
            store.commit('setUser', data);
            return data;
        }
    }).catch(() => {
        store.commit('setUser', null);
        return null;
    });
}

Hub.listen("auth", async (data) => {
    if (data.payload.event === 'signOut') {
        user = null;
        store.commit('setUser', null);
        router.push({path: '/'});
    } else if (data.payload.event === 'signIn') {
        user = await getUser();
        router.push({path: '/meetings'});
    }
});

const routes = [
    {
        path: "/",
        name: "Signin",
        component: Signin,
    },
    {
        path: "/meetings",
        name: "Meetings",
        component: Meetings,
        meta: {requiresAuth: true}
    },
    {
        path: "/db",
        name: "Database",
        component: Database,
        meta: {requiresAuth: true}
    },
    {
        path: "/registrations",
        name: "Registrations",
        component: Registrations,
        meta: {requiresAuth: true}
    },
    {
        path: "/admin",
        name: "Admin",
        component: Admin,
        meta: {requiresAuth: true}
    },
    {
        path: "/comment",
        name: "Comment",
        component: Comment,
        meta: {requiresAuth: true}
    },
    {
        path: "/inputs",
        name: "Inputs",
        component: Inputs,
        meta: {requiresAuth: true}
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        // component: function () {
        //   return import(/* webpackChunkName: "about" */ "../views/Inputs.vue");
        // },
    },

];
const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHashHistory(),
    routes, // short for `routes: routes`
})

router.beforeResolve(async (to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        user = await getUser();
        if (!user) {
            return next({
                path: '/'
            });
        }
        return next()
    }
    return next()
});

loadFonts()

Amplify.configure(awsconfig);
API.configure(awsconfig);
Auth.configure(awsconfig);

createApp(App)
    .use(AmplifyVue)
    .use(PrimeVue)
    .use(ConfirmationService)
    .use(ToastService)
    .use(moment)
    .use(plugin, defaultConfig)
    .use(vuetify)
    .use(router)
    .mount('#app')


applyPolyfills().then(() => {
    defineCustomElements(window);
});
