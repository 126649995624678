<template>
  <CommonEntitiesLoader @entities="receiveCommonEntities"/>
  <Toast/>
  
  <div width="90%" style="margin: 30px">

    <v-card class="mx-auto mt-9" >
      <Accordion lazy>
        <AccordionTab
            v-if="barriers_data"
        >
          <template #header>

            <div class="row">
              <div class="col-2" style="font-size:1.1rem"><b>{{ barriers_data.name }}</b></div>
              <div class="col-1">{{barriers_data.rules.length+"&nbsp;rules"}}</div>
              <div class="col-2">{{"order&nbsp;"+barriers_data.order}}</div>
              <div class="col-2">{{"ranking&nbsp;"+barriers_data.ranking}}</div>
              <div class="col-2">{{"usecount&nbsp;"+barriers_data.usecount}}</div>
            </div>
          </template>
          <Accordion lazy>
            
            <AccordionTab
                v-for="rule of barriers_data.rules"
                :key="rule.order"
            >
              <template #header>
                <v-row>
                  
                  <div
                      class="v-col-3 d-flex justify-start"
                      style="font-weight: 600"
                  >
                    {{
                       rule.name
                    }}
                  </div>
                                    
                  <div
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>active:</b>
                    {{
                      rule.active.toString()
                    }}
                  </div>
                  <div
                      class="v-col d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>order:</b>
                    {{
                      rule.order
                    }}
                  </div>
                  <div
                      class="v-col d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>direction:</b>
                    {{
                      rule.direction
                    }}
                  </div>
                  
                </v-row>
              </template>
              <div class="formgrid grid">
                <div class="row">
                  <v-col>{{
                    "<b>description</b>&nbsp;"+rule.description
                  }}</v-col>
                </div>
                <div class="row" v-for="parameter of rule.ruleParameters" :key="parameter.index">
                  <v-col>{{ translateParam(parameter) }}</v-col>
                </div>
                <div class="row">
                  
                  
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </AccordionTab>
        <AccordionTab
            v-if="horseprofile_data"
        >
          <template #header>

            <div class="row">
              <div class="col-2" style="font-size:1.1rem"><b>{{ horseprofile_data.name }}</b></div>
              <div class="col-1">{{horseprofile_data.rules.length+"&nbsp;rules"}}</div>
              <div class="col-2">{{"order&nbsp;"+horseprofile_data.order}}</div>
              <div class="col-2">{{"ranking&nbsp;"+horseprofile_data.ranking}}</div>
              <div class="col-2">{{"usecount&nbsp;"+horseprofile_data.usecount}}</div>
            </div>
          </template>
          <Accordion lazy>
            
            <AccordionTab
                v-for="rule of horseprofile_data.rules"
                :key="rule.order"
            >
              <template #header>
                <v-row>
                  
                  <div
                      class="v-col-3 d-flex justify-start"
                      style="font-weight: 600"
                  >
                    {{
                       rule.name
                    }}
                  </div>
                                    
                  <div
                      class="v-col-2 d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>active:</b>
                    {{
                      rule.active.toString()
                    }}
                  </div>
                  <div
                      class="v-col d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>order:</b>
                    {{
                      rule.order
                    }}
                  </div>
                  <div
                      class="v-col d-flex justify-start"
                      style="font-weight: normal"
                  >
                    <b>direction:</b>
                    {{
                      rule.direction
                    }}
                  </div>
                  
                </v-row>
              </template>
              <div class="formgrid grid">
                <div class="row">
                  <div class="col" v-html="'<b>description</b>&nbsp;'+rule.description" />
                </div>
                <div class="row" v-for="parameter of rule.ruleParameters" :key="parameter.index">
                  <v-col>{{ translateParam(parameter) }}</v-col>
                </div>
                <div class="row">
                  
                  
                </div>
              </div>
            </AccordionTab>
          </Accordion>
        </AccordionTab>
        
      </Accordion>
    </v-card>
  </div>
</template>

<script>
//import {API} from "aws-amplify";
//import moment from "moment";
import Accordion from "primevue/accordion";
import AccordionTab from "primevue/accordiontab";
import barriers_json from './rules_json/barriers.json'
import horseprofile_json from './rules_json/horseprofile.json'


export default {
  components: {
    Accordion,
    AccordionTab
  },
  data: () => ({
    // barriers_data: barriers_json,
    // horseprofile_data: horseprofile_json,
    barriers_data: undefined,
    horseprofile_data: undefined,
    errors: [],
    translationKey: {
      "compare" : "Compare: ",
      "count" : "Count: ",
      "currentHorse" : "upcoming horse ",
      "currentRace" : "upcoming race ",
      "currentMeeting" : "upcoming meeting ",
      "dbHorse" : "horse db entry ",
      "dbForm" : "horse form ",
      "dbTrialForm" : "horse form, including trials ",
      "staticValue" : "",
      "lessThan" : "is less than ",
      "greaterThan" : "is greater than ",
      "equalTo" : "is equal to ",
      "notEqualTo" : "is not equal to ",
      "*" : "times ",
      "/" : "divided by ",
      "-" : "minus ",
      "+" : "plus "
    },
    model: undefined,
  }),
  async created() {
    this.barriers_data = barriers_json;
    this.horseprofile_data = horseprofile_json;
    console.log(this.barriers_data.rules)
  },
  methods: {
    translateParam(param) {
      console.log(param);
      console.log(param.maxvalue !== undefined)
      let paramstring = "";
      paramstring += this.translationKey[param.type];
      if (param.type === "count" && !param.compare1.field) {
        paramstring += `there are `;
      } else {
        paramstring += ``;
      }
      if (param.maxvalue !== undefined) paramstring +=  `at most ${param.maxvalue} `;
      if (param.minvalue !== undefined) paramstring +=  `at least ${param.minvalue} `;
      if (param.consecutive) paramstring += `latest `;
      
      if (param.compare1){
        paramstring += this.compilePhase(param.compare1)
      }
      if (param.compareFunc) paramstring += `${this.translationKey[param.compareFunc]} `;
      if (param.compare2){
        paramstring += this.compilePhase(param.compare2)
      }
      if (param.campaign){
        let campaign = "in ";
        if (param.campaignrun) campaign += `up ${param.campaignrun} of `;
        campaign += `${param.campaign} campaign, `;
        if (param.campaignrunexclude) campaign += `excluding up ${param.campaignrunexclude}, `;
        paramstring += campaign;
      }
      paramstring = paramstring.trim();
      paramstring = paramstring.replace(/(,|\.)$/g, "");
      paramstring += ".";
      return paramstring;

    },
    compilePhase(phase){
      let phaseString = "";
      if (phase.field !== undefined) phaseString += `${phase.field} in `
      if (phase.number !== undefined) phaseString += `number ${phase.number} most recent `;
      phaseString += `${this.translationKey[phase.phase]} `;
      if (phase.value !== undefined) phaseString += `${phase.value.toString()} `;
      if (phase.modifyOperator !== undefined)  phaseString += `${this.translationKey[phase.modifyOperator]} `;
      if (phase.modifyValue !== undefined)  phaseString += `${phase.modifyValue} `;
      if (phase.isJumps !== undefined){
        if (phase.isJumps)  phaseString += `jump races only `;
        if (!phase.isJumps)  phaseString += `flat races only `;
      }
      return phaseString;
    }
  },
  watch: {

  }
  ,
  computed: {
    
  }
  ,
  mounted: () => {
    // this.prepareDatePicker();
  },

}
;
</script>
<style scoped>
.mr-2{
  color: #2196f3;
  background: transparent;
  border: 1px solid #2196f3;
  top: -6px;
}
</style>