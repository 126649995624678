<template>
  <div :style="{ backgroundColor: backgroundColor }">
    <v-app-bar app :color="appBarColor">
      <v-img
        class="mx-2 row-pointer"
        max-height="40"
        max-width="220"
        style="cursor: pointer"
        :src="require('../assets/medialityracing-logotype-reverse.svg')"
        @click="$router.push('/meetings')"
      >
      </v-img>
      <v-spacer></v-spacer>
      <Button class="p-button-text" @click="this.$router.push('/meetings')">
        <v-icon left> mdi-horse-variant</v-icon>
        &nbsp;Meetings
      </Button>
      <Button class="p-button-text" @click="this.$router.push('/inputs')">
        <v-icon left> mdi-cloud-download</v-icon>
        &nbsp;Inputs
      </Button>
      <v-menu v-if="user_role < 7" bottom :offset-y="true" :close-on-content-click="true" v-model="menuVisibility.database">
        <template v-slot:activator="{ props }">
          <Button class="p-button-text" v-bind="props">
            <v-icon left> mdi-database</v-icon>
            &nbsp;Database
          </Button>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in db_items"
            :key="i"
            @click="navigateTo(item.entity)"
          >
            <v-list-item-title>
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <Button v-if="user_role < 5"
        class="p-button-text"
        @click="this.$router.push('/registrations')"
      >
        <v-icon left> mdi-horseshoe</v-icon>
        &nbsp;Registrations
      </Button>
      <Button v-if="user_role < 2"
        class="p-button-text"
        @click="this.$router.push('/comment')"
      >
        Comment
      </Button>
      <v-menu v-if="user_role < 4" bottom :offset-y="true" :close-on-content-click="true" v-model="menuVisibility.admin">
        <template v-slot:activator="{ props }">
          <Button class="p-button-text" v-bind="props">
            <v-icon left> mdi-account-key</v-icon>
            &nbsp;Admin
          </Button>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in admin_items"
            :key="i"
            @click="navigateTo(item.entity, '#/admin?entity=')"
          >
            <v-list-item-title>
              <v-icon>{{ item.icon }}</v-icon>
              {{ item.text }}
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <Toast />
      <Button
        class="p-button-text"
        @dragover.prevent="handleDragOver"
        @drop.prevent="handleFileDrop"
        @click="triggerFileInput"
        v-if="!selectedFileName"
      >
        <v-icon left>mdi mdi-file-upload</v-icon>
        &nbsp;Drop files here or click to upload
        <input
          type="file"
          @change="handleFileSelect"
          ref="fileInput"
          style="display: none"
          accept=".xml,.json"
        />
      </Button>
      <div v-if="selectedFileName">
        File is uploading {{ selectedFileName }}
      </div>
      &nbsp;

      <v-menu bottom :offset-y="true" :close-on-content-click="true">
        <template v-slot:activator="{ props }">
          <Button class="p-button-text" icon v-bind="props">
            <v-icon>mdi-dots-vertical</v-icon>
          </Button>
        </template>

        <v-list style="position: relative; top: 20px; left: -120px">
          <v-list-item>
            <v-switch
              v-on:click="toggleTheme"
              inset
              label="Toggle Theme"
              dense
            ></v-switch>
          </v-list-item>
          <v-list-item>
            <v-btn @click="logout">
              <v-icon>mdi-logout</v-icon>
              Logout
            </v-btn>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
  </div>
</template>

<script>
import { Auth } from "aws-amplify";
import { ref } from "vue";
import Button from "primevue/button";
import { API } from "aws-amplify";
import Toast from 'primevue/toast'

export default {
  components: {
    Button,
    Toast
  },
  data: () => ({
    menuVisibility: {
      database: false, // for the database menu
      admin: false, // for the admin menu
    },
    show_badge: false,
    selectedFile: null,
    selectedFileName: "",
    db_items: [
      {
        icon: "mdi-horse",
        text: "Horses",
        entity: "horses",
      },
      {
        icon: "mdi-form-select",
        text: "Horse Form",
        entity: "horseforms",
      },
      {
        icon: "mdi-horse-human",
        text: "Jockeys",
        entity: "jockeys",
      },
      {
        icon: "mdi-human-male-board",
        text: "Trainers",
        entity: "trainers",
      },
      {
        icon: "mdi-road-variant",
        text: "Tracks",
        entity: "tracks",
      },
      {
        icon: "mdi-account-group",
        text: "Clubs",
        entity: "clubs",
      },
    ],

    admin_items: [
      {
        icon: "mdi-account-multiple",
        text: "Clients",
        entity: "client",
      },
    ],
    current_user: null,
    user_role: null
  }),
  async created() {
    this.current_user = await Auth.currentUserInfo();
    this.user_role = (this.current_user && this.current_user.attributes && this.current_user.attributes["custom:permissions"]) ? this.current_user.attributes["custom:permissions"] : null;
  },
  computed: {
    backgroundColor() {
      return this.isAdmin() ? 'white' : 'green';
    },
    appBarColor() {
      return this.isAdmin() ? 'info' : 'success';
    }
  },
  methods: {
    isAdmin() {
      return window.location.href.startsWith('https://www.admin.medialityracing.com.au/');
    },
    navigateTo(entity, path = "#/db?entity=") {
      try {
        const destination = path + entity;
        this.menuVisibility.database = false; 
        this.menuVisibility.admin = false; 
        if (window.location.pathname + window.location.search !== destination)
          this.$router.push(destination);
      } catch (error) {
        console.log(error);
      }
    },

    async logout() {
      try {
        await Auth.signOut();
      } catch (error) {
        console.log(error);
        alert(error.message);
      }
    },
    handleDragOver(event) {
      console.log("drag over");
      console.log(event.target);
    },

    handleFileDrop(event) {
      const files = event.dataTransfer.files;
      this.processFile(files);
    },
    processFile(files) {
      if (files.length === 0) {
        this.selectedFileName = "";
        this.selectedFile = null;
        return;
      }
      const file = files[0];
      if (this.isValidFileType(file)) {
        this.selectedFile = file;
        this.selectedFileName = file.name;
        this.uploadFile(file);
      } else {
        console.log("Invalid file type:", file.type); 
        alert("Invalid file type. Only XML and JSON files are allowed.");
        this.selectedFileName = "";
        this.selectedFile = null;
      }
    },
    handleFileSelect(event) {
      const files = event.target.files;
      this.processFile(files);
    },
    isValidFileType(file) {
      return ["application/xml", "text/xml", "application/json"].includes(
        file.type
      );
    },
    triggerFileInput() {
      this.$refs.fileInput.click();
    },
    async uploadFile() {
      if (!this.selectedFile) {
        alert("Please select a file first.");
        return;
      }
      const fileType = this.selectedFile.type;
      API.get("MrCenApiGateway", `/fileHandler`, {
        queryStringParameters: {
          fileName: this.selectedFileName,
          fileType: fileType,
        },
      })
        .then((response) => {
          const bodyString = response.data.body;
          const bodyObject = JSON.parse(bodyString);
          const uploadUrl = bodyObject.url;

          return fetch(uploadUrl, {
            method: "PUT",
            body: this.selectedFile,
            headers: {
              "Content-Type": this.selectedFile.type,
            },
          });
        })
        .then((uploadResponse) => {
          if (uploadResponse.status == 200) {
            this.$toast.add({
              severity: "success",
              summary: `The file ${this.selectedFileName} was uploaded successfully`,
              life: 5000,
            });
            this.selectedFileName = "";
            this.selectedFile = null;
          }
        })
        .catch((error) => {
          console.error("Upload error:", error);
          this.selectedFileName = "";
          this.selectedFile = null;
          this.$toast.add({
            severity: "error",
            summary: `The file upload failed!!`,
            life: 5000,
          });
        });
    },
  },
  mounted() {
    const theme = ref("light");

    return {
      theme,
      toggleTheme: () =>
        (theme.value = theme.value === "light" ? "dark" : "light"),
    };
  },
};
</script>

<style scoped>
html {
  font-size: 0.8em;
}

.p-button {
  margin-left: 5px;
  margin-right: 5px;
}

.p-button.p-button-text {
  background-color: transparent;
  color: white;
}

button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

</style>
